<template>
  <div id="businessCooperation">
    <!-- 头部组件 -->
    <headPage />
    <!-- 轮播图 -->
    <carousel />

    <!--  -->
    <div class="container">
      <div class="containerDetail_box">
        <h1>多重安全防护&nbsp;&nbsp;为学习保驾护航</h1>
        <div class="container_box">
          <div v-for="(item, index) in threeLevelList" :key="index">
            <img :src="item.icon" alt="" />
            <h2 class="threeTitle">
              {{ item.title }}
            </h2>
            <p class="threeContent">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="levelORa">
      <div class="levelPublic">
        <h1>平台通过公安部信息系统安全等级保护三级认证</h1>
        <p class="p">
         企业的信息安全管理能力达到国内领先水平。
        </p>
        <div class="levelPublicImgBox">
          <div>
            <img src="@/assets/loginIndex/prove.png" alt="" />
          </div>
          <div>
            <img src="@/assets/loginIndex/prove1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="LevelCertification">
      <div class="LevelCertificationContent">
        <h1>安全等级保护三级认证的测评标准</h1>
        <p class="p">
          根据《信息系统安全等级保护基本要求》，三级等保的测评内容涵盖等级保护安全技术要求的5个层面和安全管理要求的5个层面，包含信息保护、安全审计、通信保密等在内的近300项要求，共涉及测评分类73类。
        </p>
        <div class="levelPublicImgBox">
          <div v-for="(item, index) in LevelCertificationList" :key="index">
            <img :src="item.icon" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部组件 -->
    <bottomPage />
  </div>
</template>

<script>
import headPage from "../../wrongAuthenticatePage/headPage.vue";
import bottomPage from "../../wrongAuthenticatePage/bottomPage.vue";
import carousel from "../../wrongAuthenticatePage/carousel.vue";
export default {
  name: "wrongAuthenticatePage_aboutUs",
  components: {
    carousel,
    headPage,
    bottomPage,
  },
  data() {
    return {
      bannerList: [], // 轮播图列表
      threeLevelList: [
     
        {
          icon: require("@/assets/loginIndex/icon10.png"),
          title: "数据信息安全",
          content:
            "数据传输采用SSL加密，关键数据加密存储，避免数据泄露、篡改。",
        },
        {
          icon: require("@/assets/loginIndex/icon11.png"),
          title: "版权保护机制",
          content:
            "视频加密、授权播放、固定水印、跑马灯、防下载等方式，保障课程版权安全。。",
        },
        {
          icon: require("@/assets/loginIndex/icon12.png"),
          title: "平台运营保障",
          content:
            "24小时实时监控服务状态，发现问题快速响应及时处理，确保系统稳定性。",
        },
           {
          icon: require("@/assets/loginIndex/icon9.png"),
          title: "系统安全部署",
          content:
            "云服务部署，具有漏洞检测修复、内容防篡改、防病毒等功能，保证系统安全稳定运行。",
        },
      ],
      LevelCertificationList: [
        {
          icon: require("@/assets/loginIndex/icon9.png"),
          title: "物理安全",
        },
        {
          icon: require("@/assets/loginIndex/icon13.png"),
          title: "网络安全",
        },
        {
          icon: require("@/assets/loginIndex/icon12.png"),
          title: "主机安全",
        },
        {
          icon: require("@/assets/loginIndex/icon11.png"),
          title: "应用安全",
        },
        {
          icon: require("@/assets/loginIndex/icon10.png"),
          title: "数据安全",
        },
      ],
    };
  },
  created() {
    // this.getBanner();
  },
  methods: {
  
  },
};
</script>

<style lang="less">
#businessCooperation {
  height: 100vh;
  background: #fff;
  overflow-y: auto;
  .container {
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .containerDetail_box {
    width: 1168px;
    padding-top: 3rem;
    text-align: center;
    h1 {
      font-size: 34px;
    }
  }
  .container {
    width: 1168px;
    // text-align: center;
    margin: 0 auto;
    .container_box {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0 3rem;

      div {
        border: 1px solid #edf6ff;
        border-radius: 0.4rem;
        padding: 30px 30px;
        background: #fff;
        width: 280px;
        .threeTitle {
          font-size: 20px;
          line-height: 20px;
          padding-top: 20px;
        }
        .threeContent {
          font-size: 14px;
          color: #5c6b84;
          margin-top: 16px;
          text-align: left;
        }
      }
      > div:hover {
        cursor: pointer;
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }
  }

  .levelORa {
    width: 100%;
    background: #f8fafd;
    padding: 60px 0;
    .levelPublic {
      width: 1168px;
      margin: 0 auto;
      h1 {
        font-size: 34px;
        color: #3f4652;
        text-align: center;
      }
      p {
        font-size: 17px;
        color: #5c6b84;
        text-align: center;
        padding: 16px 0 40px;
      }
      .levelPublicImgBox {
        display: flex;
      }
    }
  }
  .LevelCertification {
    width: 100%;
    background: #fff;
    padding: 60px 0;
    .LevelCertificationContent {
      width: 1168px;
      margin: 0 auto;
      h1 {
        font-size: 34px;
        color: #3f4652;
        text-align: center;
      }
      .p {
        font-size: 17px;
        color: #5c6b84;
        text-align: center;
        padding: 16px 0 16px;
      }
      .levelPublicImgBox {
        display: flex;
        justify-content: space-between;
        padding-top: 36px;
        > div {
          border: 1px solid #edf6ff;
          border-radius: 0.4rem;
          padding: 30px 30px;
          background: #fff;
          width: 221px;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-direction: column;
          p {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
</style>